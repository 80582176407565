<template>
  <Auth v-if="!alreadyConnected && !isLoading">
    <div class="poulpe"><img src="@/assets/images/poulpe_welcome.png" /></div>

<h2 v-if="devMode" class="title title--2">Connectez-vous</h2>
    <form v-if="devMode" class="connection-form" @submit.prevent="login">
  <div class="form-row">
    <input
      v-model="email"
      type="email"
      class="input"
      placeholder="Votre email"
    />
  </div>
  <div class="form-row">
    <input
      v-model="password"
      type="password"
      class="input"
      placeholder="********"
    />
  </div>
  <div v-if="connectionAvaibility === false">
    <p class="text text--not-available">
      <img src="@/assets/svg/Bad-Check.svg" alt="" />
      <br />
      Vos identifiants sont incorrects
    </p>
  </div>
  <button class="button connection-form__cta" type="submit">
    Se connecter
  </button>
</form>
    <!--
<p class="text text--with-margin-top">
Vous n'avez pas de compte ?<br />
<a
class="button--link"
href="https://www.solency.com/?redirect_to=https%3A%2F%2Fmonespace.solency.com%2F"
>
Créer mon compte Solency
</a>
</p>
-->
  </Auth>
</template>

<script>
import { mapActions } from 'vuex';
import { Auth } from '@/components';
import { api } from '@/lib/api';

export default {
  components: { Auth },

  data() {
    return {
      devMode: true,
      isLoading: false,
      alreadyConnected: true,
      email: '',
      password: '',
      connectionAvaibility: null,
    };
  },

  watch: {
    email() {
      this.connectionAvaibility = null;
    },

    password() {
      this.connectionAvaibility = null;
    },
  },

  async created() {
    this.devMode = ['localhost', 'monespace.solency.wokine.dev', 'monespace.preprod.solency.com'].includes(window.location.hostname);
    if (this.$route.query.t) {
      this.isLoading = true;
      try {
        const {
          data: { token, id },
        } = await api.post('/log_via_token?t=' + this.$route.query.t, {
          t: this.$route.query.t,
        });
        await this.updateAuth(token);
        await this.updateId(id);
        if (this.$route.query.redirectTo) {
          return this.$router.push(this.$route.query.redirectTo);
        }
        this.$router.push({ name: 'DashboardHome' });
        this.isLoading = false;
      } catch {
        this.connectionAvaibility = false;
        this.alreadyConnected = false;
        this.isLoading = false;
      }
    } else {
      this.alreadyConnected = false;
    }
  },

  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content:
            'Connectez-vous à votre compte Solency afin de gérer votre site web pour votre cabinet d’avocats.' +
            ' Découvrez aussi la marketplace et nos partenaires qui vous aideront à trouver des clients' +
            ' et digitaliser votre cabinet.',
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      updateAuth: 'auth/updateAuth',
      updateId: 'auth/updateId',
    }),
    async login() {
      try {
        const {
          data: { token },
        } = await api.post('/auth_tokens', {
          email: this.email,
          password: this.password,
        });
        this.updateAuth(token);

        if (this.$route.query.redirectTo) {
          return this.$router.push(this.$route.query.redirectTo);
        }

        this.$router.push({ name: 'DashboardHome' });
      } catch {
        this.connectionAvaibility = false;
      }
    },
  },
};
</script>
